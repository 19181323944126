<template>
<v-container style="margin-top:160px;"  fluid>

  <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        >
        <v-textarea
            solo
            name="input-7-4"
            label="GPS DATA"
            v-model="gps_data"
             auto-grow
          ></v-textarea>
        
        
        </v-col><v-col cols="1"></v-col>
       
    </v-row>
   <v-row class="ma-1 pa-0" >
      <v-col cols="1"></v-col>
      <v-col cols="10" class="ma-1 pa-0"
        >
        <a href="#" onclick="window.open('viber://pa?chatURI=itudatamatrixtest','_blank')">Назад в бот</a>
        
        
        </v-col><v-col cols="1"></v-col>
       
    </v-row>


</v-container>
</template>

<script>
import axios from "axios";
import { API_LOCATION } from "../../config";
//import btnsqr from "../components/btnsqr.vue";
//import l_switch from "../components/lang_switch.vue";
export default {
  watch:{
    

    

  },
  
  computed: {
   
  },
  data: () => ({
    dialog_netw_confirm:false,
    dialog_sku_confirm:false,
    sku_lock:false,
    cd_enter:false,
    cd_lock:false,
    pass_lock:false,
    pos_code:'C-',
    network_lock:false,
    deleted_lock:false,
    task_pending:0,
    menu_active:true,
    pass_change:false,
    password_old:null,
    password_new:null,
    password_new_confirm:null,
    show1: false,
    show2: false,
    show3: false,
     g:{long:'',lat:'',coord_state:''},
     gps_data:'',
    
  }), 
  methods: {
    navOnSuccess(position) {
                 console.log('Latitude: '          + position.coords.latitude          + '\n' +
                        'Longitude: '         + position.coords.longitude         + '\n' +
                        'Altitude: '          + position.coords.altitude          + '\n' +
                        'Accuracy: '          + position.coords.accuracy          + '\n' +
                        'Altitude Accuracy: ' + position.coords.altitudeAccuracy  + '\n' +
                        'Heading: '           + position.coords.heading           + '\n' +
                        'Speed: '             + position.coords.speed             + '\n' +
                        'Timestamp: '         + position.timestamp                + '\n');
                     this.gps_data=   'Latitude: '          + position.coords.latitude          + '\n' +
                        'Longitude: '         + position.coords.longitude         + '\n' +
                        'Altitude: '          + position.coords.altitude          + '\n' +
                        'Accuracy: '          + position.coords.accuracy          + '\n' +
                        'Altitude Accuracy: ' + position.coords.altitudeAccuracy  + '\n' +
                        'Heading: '           + position.coords.heading           + '\n' +
                        'Speed: '             + position.coords.speed             + '\n' +
                        'Timestamp: '         + position.timestamp                + '\n';
                        
                        this.g.long = position.coords.longitude
                        this.g.lat = position.coords.latitude
                        this.g.coord_state='success'
                        console.log('long: '+this.g.long+': lat:'+this.g.lat)
                        //alert('long: '+this.g.long+': lat:'+this.g.lat)
                        console.log(this.g);

              },

              navOnError(error) {
                  console.log('NAV_FAIL: code: '    + error.code    + '\n' +
                        'message: ' + error.message + '\n');

                  alert('NAV_FAIL: code: '    + error.code    + '\n' +
                        'message: ' + error.message + '\n');
              },
    logout: function () {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    pass_set_new:function(){
            axios
      .post(
        API_LOCATION,
        {
          
          action: "pass_set_new",
          pass_old:this.password_old,
          pass_new1: this.password_new,
          pass_new2: this.password_new_confirm,
          temp:true
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data);
        if(resp.data.pass_set!='ok'&&resp.data.debug!='on')
        {
          alert(this.$t(resp.data.error));
        }
        else
        {
          alert(this.$t('success_change'));
          this.pass_change=false;
          this.logout();

        }
      });


    
  },cd_set:function(){
            axios
      .post(
        API_LOCATION,
        {
          
          action: "cd_update",
          pos_code: this.pos_code
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
      
        console.log(resp.data);
        if(resp.data.reg_final!='ok'&&resp.data.debug!='on')
        {
          alert(this.$t(resp.data.error));
        }
        else
        {
               
                this.cd_enter=false;
                this.cd_lock=false;
                if(!this.cd_lock&&!this.pass_lock) { this.menu_active=true;}
        }
      });


    
  },
   get_cd_exist: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "is_cd_exist"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
            console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!CD'+resp.data);
              if(resp.data==false)         {
                this.menu_active=false;
                this.cd_enter=true;
                this.cd_lock=true;
              } 
              
        
      });

    },
    get_temp: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "is_temp_pass"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              if(resp.data==1)         {
                this.menu_active=false;
                this.pass_change=true;
                this.pass_lock=true;
              } 
              
        
      });

    },
    get_k_type: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "k_type"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              if(resp.data==9)         {
                this.$router.push("/Asssel");
              } 
              else if(resp.data==10)     {
                 this.$router.push("/Asssel");
              }
        
      });

    },
    get_pending_task: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "pending_task"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              this.task_pending=resp.data;
              console.log('PENDING:'+resp.data);
              
        
      });

    },
    get_pending_network_confirm: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "is_network_pending"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              if(resp.data==1)         {
                this.dialog_netw_confirm=true;
                this.network_lock=true
              }
        
      });

    },
    get_pending_sku_confirm: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "is_sku_pending"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              if(resp.data==1)         {
                this.dialog_sku_confirm=true;
                this.sku_lock=true;
              }
        
      });

    },
    get_pending_profile: function(){
      axios
      .post(
        API_LOCATION,
        {
          
          action: "get_pending_profile"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
            if(resp.data==0)
            {
            this.$router.push("/Profile");
            }
            console.log('PROFILE',resp.data)
        
      });

    },
    get_pending_sku_change: function(){

      axios
      .post(
        API_LOCATION,
        {
          
          action: "sku_change"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
            if(resp.data==1)
            {
            this.$router.push({
                     name: 'Asssel',
                     params: { change_type: 1 },
                     })
            }
           
        
      });

    },
    get_pending_reg_complete: function(){

    }
    
  },
  created() {
    /*
    this.get_cd_exist();
    this.get_temp();
    this.get_pending_task();
    this.get_pending_profile();
    this.get_pending_sku_change();*/
          if (navigator.geolocation)
              {
              navigator.geolocation.getCurrentPosition(this.navOnSuccess, this.navOnError,{enableHighAccuracy:true,maximumAge:Infinity, timeout:60000})
               console.log('start_nav_detect')
              }
              else
              {
                console.log('geolocation_not_supported')
              }

    /*this.$store.dispatch("profileset");*/
    
    this.$store.dispatch("page_title_set", "Головна");  
    
    axios
      .post(
        API_LOCATION,
        {
          
          action: "api_check"
          
        },
        { headers: { "content-type": "application/x-www-form-urlencoded" } }
      )
      .then((resp) => {
              console.log(resp.data);         
        
      });

      /*
    this.get_k_type();
    this.get_pending_sku_confirm();
    this.get_pending_network_confirm();
    */








  },
};
</script>


<i18n>
{
  "ua": {
    "wrong_old":"Помилковий старий пароль",
"pass not match":"Паролі не співпадають",
    "pass insecure":"Довжина паролю має бути від 6 символів",
    "success_change":"Пароль успішно змінено, увійдіть до додатку з новим паролем",
"close":"Закрити",
 "check_pos":"Вказана точка відсутня в базі",
    "check_pos 2 users exist":"На вказаній точці вже зареестрований користувач"

  }
}
</i18n>